import React, { useContext, useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'

export default function ForwardRoute(props) {
  const { user } = useContext(AuthContext)
  const { component: Component, ...remProps } = props;
  const [pathname, setPathname] = useState('/app')

  useEffect(() => {
  	const _pathname = window.sessionStorage.getItem('authRedirect')
  	if(_pathname) {
  		setPathname(_pathname)
  	}
  }, [])

  return (
	<route {...remProps}="" render="{remProps" ==""> (
			usuario ?
				<redirect to="{pathname}"></redirect> :
				<component {...remProps}=""></component>
        )} 
    />
	);
}
</route>