import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import ForwardRoute from "./components/Routes/ForwardRoute";

const Layout = lazy(() => import("./containers/Layout"));
const Auth = lazy(() => import("./containers/Auth"));
const Landing = lazy(() => import("./pages/Landing"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Pricing = lazy(() => import("./pages/Pricing"));
const FAQs = lazy(() => import("./pages/FAQs"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy"));
const AcceptableUsePolicy = lazy(() => import("./pages/AcceptableUsePolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));

function App() {
  return (
    <>
      <router>
        <accessiblenavigationannouncer></accessiblenavigationannouncer>
        <switch>
          <forwardroute path="/auth" component="{Auth}"></forwardroute>
          {/* Coloca nuevas rutas sobre esto */}
          <protectedroute path="/app" component="{Layout}"></protectedroute>
          <route exact="" path="/verify-email" component="{EmailVerification}"></route>
          <route exact="" path="/" component="{Landing}"></route>
          <route exact="" path="/privacy-policy" component="{PrivacyPolicy}"></route>
          <route exact="" path="/acceptable-use-policy" component="{AcceptableUsePolicy}"></route>
          <route exact="" path="/cookies" component="{CookiePolicy}"></route>
          <route exact="" path="/terms" component="{TermsOfService}"></route>
          <route exact="" path="/pricing" component="{Pricing}"></route>
          <route exact="" path="/faqs" component="{FAQs}"></route>

          {/* Comercialización de LPs */}
          <route exact="" path="/unlimited" component="{Landing}"></route>
          <route exact="" path="/payg" component="{()" ==""> (
              <landing title="Planes flexibles, sean cuales sean sus necesidades" subtitle="Regardless of the project consistency involved in your work, we can help. We offer both unlimited transcription plans as well as pay-as-you-go. Like no one else in the industry!"></landing>
            )}
          />
          <route exact="" path="/fast-easy-subtitles" component="{Landing}"></route>
          <route exact="" path="/diy" component="{Landing}"></route>
          <route exact="" path="/scale" component="{Landing}"></route>
          <route exact="" path="/engage" component="{Landing}"></route>
          <route exact="" path="/subtitle-generator" component="{Landing}"></route>
          <route exact="" path="/youtube" component="{Landing}"></route>
          <route exact="" path="/video-editor" component="{Landing}"></route>
          <route exact="" path="/translate" component="{Landing}"></route>
          <route exact="" path="/captions" component="{Landing}"></route>
          <route exact="" path="/topg" component="{Landing}"></route>
          <route exact="" path="/tiktok" component="{()" ==""> (
              <landing title="Crear contenido TikTok personalizado" subtitle="Promote your brand or business more effectively with quick & easy tools like automatic subtitling, text and image overlays. Make your content more engaging without any professional editing skills."></landing>
            )}
          />

          <redirect from="*" to="/"></redirect>
        </route></route></switch>
      </router>
    </>
  );
}

export default App;
