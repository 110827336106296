import React, { useState, useMemo } from 'react'

// crear contexto
export const SidebarContext = React.createContext()

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const value = useMemo(
    () => {
      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
      }

      const closeSidebar = () => {
        setIsSidebarOpen(false)
      }

      return ({
        isSidebarOpen,
        toggleSidebar,
        closeSidebar,
      })
    },
    [isSidebarOpen]
  )

  devolver <sidebarcontext.provider value="{value}">{niños}</sidebarcontext.provider>
}
