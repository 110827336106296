import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import { SnackbarProvider } from './context/SnackbarContext'
import { AuthProvider } from './context/AuthContext'
import { StripeProvider } from './context/StripeContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import * as serviceWorker from './serviceWorker'
// import * as Sentry from '@sentry/react'
import {BrowserTracing} from '@sentry/tracing'
import { config } from './assets/config/config'

// Sentry.init({
//   dsn: "https://9801423c42c445709aa381645779a360@o1259622.ingest.sentry.io/6434607",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: .5,
//   environment: config.environment
// })

ReactDOM.render(
  <sidebarprovider>
    <snackbarprovider>
      <stripeprovider>
        <authprovider>
          <suspense fallback="{<ThemedSuspense"></suspense>}>
            <windmill usePreferences="">
              <app></app>
            </windmill>
          
        </authprovider>
      </stripeprovider>
    </snackbarprovider>
  </sidebarprovider>,
  document.getElementById('root')
)

// Si quieres que tu app funcione offline y cargue más rápido, puedes cambiar
// unregister() por register(). Ten en cuenta que esto conlleva algunos inconvenientes.
// Más información sobre service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
