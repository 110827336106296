import React, { useState, useMemo, useEffect, useCallback } from 'react'
import axios from 'axios'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import { config } from '../assets/config/config'
import ThemedSuspense from '../components/ThemedSuspense'

const apiUrl = config.api.url

// crear contexto
export const StripeContext = React.createContext()

export const StripeProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false)
  const [products, setProducts] = useState([])
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    setStripePromise(loadStripe(config.stripe.publicKey))
  }, [])

  const loadProducts = useCallback(
    () => {
      axios.post(`${apiUrl}/v1/stripe/get-products`, {})
      .then(response => {
        setLoaded(true)
        setProducts(response.data.products)
      })
      .catch(err => {
        setLoaded(true)
      })
    }, []
  )

  useEffect(() => {
    cargarProductos()
  }, [cargarProductos])

  const valor = useMemo(
    () => {
      return ({
        productos,
      })
    },
    [productos]
  )

  if(!isLoaded) {
      devuelva <themedsuspense></themedsuspense>
  }

  return (
    <stripecontext.provider value="{value}">
      <elements stripe="{stripePromise}">
        {niños}
      </elements>
    </stripecontext.provider>
  )
}
