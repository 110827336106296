import React, { useState, useMemo } from 'react'

import Snackbar from '../components/Snackbar/Snackbar'

// crear contexto
export const SnackbarContext = React.createContext()

export const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState('Cargando..')
  const [type, setType] = useState('neutral')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

  const valor = useMemo(
    () => {
      let timeoutRef = null
      
      const openSnackbar = (mensaje) => {
        clearTimeout(timeoutRef)
        setMessage(mensaje)
        setType('neutral')
        setIsSnackbarOpen(true)
      }

      const closeSnackbar = () => {
        setMessage('¡Listo!')
        setType('éxito')
        timeoutRef = setTimeout(() => {
          setIsSnackbarOpen(false)
        }, 1000)
      }

      return ({
        openSnackbar,
        closeSnackbar,
      })
    },
    []
  )

  return (
    <snackbarcontext.provider value="{value}">
      <snackbar isOpen="{isSnackbarOpen}" message="{message}" type="{type}/">
      {niños}
    </snackbar></snackbarcontext.provider>
  )
}
