import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function AnunciadorDeNavegaciónAccesible() {
  const [mensaje, setMensaje] = useState('')
  const location = useLocation()

  useEffect(() => {
    // ignorar el /
    if (location.pathname.slice(1)) {
      // asegúrate de que se ha producido la navegación y el lector de pantalla está listo
      setTimeout(() => setMessage(`Navegado a ${location.pathname.slice(1)} page.`), 500)
    } else {
      setMessage('')
    }
  }, [location])

  return (
   <span className="sr-only" role="status" aria-live="polite" aria-atomic="true">
      {mensaje}
   </span>
  )
}

export default AccessibleNavigationAnnouncer
